import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router";

const Header = () => {
  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  function handleHashChange() {
    const element = document.getElementById(window.location.hash.slice(1));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <>
      {/* <div id="preloader"><img src={"/assets/img/loader.gif"} alt="loader" /></div> */}
      <div className="up">
        <a href="#" className="scrollup text-center">
          <i className="fas fa-chevron-up"></i>
        </a>
      </div>

      <header id="ori-header" className="ori-header-section header-style-one">
        <div className="ori-header-content-area container">
          <div className="ori-header-content d-flex align-items-center justify-content-between">
            <div className="brand-logo">
              <NavLink to="/">
                <img
                  src={"/assets/img/logo/infodelix-logo.svg"}
                  width="210"
                  alt=""
                />
              </NavLink>
            </div>
            <div className="ori-main-navigation-area magical-nav">
              <nav className="ori-main-navigation  clearfix ul-li">
                <ul id="main-nav" className="nav navbar-nav clearfix">
                  <li>
                    <NavLink activeClassName="active" to="/#about-hd">
                      About us
                    </NavLink>
                  </li>
                  <li className="dropdown ori-megamenu">
                    <NavLink activeClassName="active" to="/#service-hd">
                      Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/portfolio">
                      Portfolio
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/#ori-sponsor-1">
                      Our clients
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/contact">
                      Contact us
                    </NavLink>
                  </li>
                  <li className="ticketRaise">
                    <a href="https://ticket.infodelix.com" target="blank">
                      Raise Ticket
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="mobile_menu position-relative">
              <div className="mobile_menu_button open_mobile_menu">
                <i className="fal fa-bars"></i>
              </div>
              <div className="mobile_menu_wrap">
                <div className="mobile_menu_overlay open_mobile_menu"></div>
                <div className="mobile_menu_content">
                  <div className="mobile_menu_close open_mobile_menu">
                    <i className="fal fa-times"></i>
                  </div>
                  <div className="m-brand-logo">
                    <NavLink to="/">
                      <img src={"/assets/img/logo/infodelix-logo.svg"} alt="" />
                    </NavLink>
                  </div>
                  <nav className="mobile-main-navigation  clearfix ul-li">
                    <ul id="m-main-nav" className="nav navbar-nav clearfix">
                      <li>
                        <NavLink activeClassName="active" to="/#about-hd">
                          About us
                        </NavLink>
                      </li>
                      <li className="dropdown ori-megamenu">
                        <NavLink to="/#service-hd">Services</NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active" to="/portfolio">
                          Portfolio
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active" to="/#ori-sponsor-1">
                          Our clients
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active" to="/contact">
                          Contact us
                        </NavLink>
                      </li>
                      <li className="ticketRaise">
                        <a href="https://ticket.infodelix.com" target="blank">
                          Ticket Raise
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
