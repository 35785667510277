import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'
const PPC = () => {
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section ppc-bg position-relative"
    style={{ backgroundColor: "#011421", backgroundImage: 'url("assets/img/headers/ppc.jpg")' }}
  >
    <div className="container">
      <span
        className="buttons prev-service wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="search-engine-optimization">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
      <div
        className="ori-breadcrumb-content text-right ul-li wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>P.P.C</span>
          <br />
          Pay Per Click
        </h1>
      </div>
      <span
        className="buttons next-service wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="social-media-presence">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
    </div>
  </section>
  {/* End of Breadcrumbs section
	============================================= */}
  {/* Start of Service Details  section
	============================================= */}
  <section
    id="ori-service-details"
    className="ori-service-details-section position-relative"
    style={{
      backgroundImage: 'url("assets/img/inner-pages/bg-home.png")',
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000000c2",
      backgroundBlendMode: "overlay"
    }}
  >
    <div className="container">
      <div className="ori-service-details-content-wrapper">
        <div className="row">
          <div className="col-lg-8">
            <div className="ori-service-details-content">
              <div className="ori-about-play-area position-relative">
                <div
                  className="ori-about-play-img wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <img
                    src="assets/img/inner-pages/svg/ppc.svg"
                    alt="Pay Per Click InfodeliX"
                  />
                </div>
              </div>
              <div
                className="ori-service-details-text pera-content wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1200ms"
              >
                <p>
                  Programmatic ad buying through PPC and CPM models has
                  transformed the way digital media is bought. It has changed
                  the way brands interact with potential customers.{" "}
                </p>
                <p>
                  With our technology solutions and diligent team, we help
                  brands have one-to-one conversations with their audiences
                  across all digital channels. Our primary goal is to help
                  maximise the ROI investment.
                </p>
                <p>
                  So, we make sure to use a programmatic channel with our
                  professional services that can include custom solutions,
                  education to bring your team up to speed if programmatic is
                  new territory, and dedicated account management with experts
                  that can help you transform your business with programmatic
                  marketing.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default PPC