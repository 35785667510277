import React, { useCallback, useRef } from 'react'
import { NavLink } from 'react-router-dom'

const Sidebar = () => {
  return (
    <>	
    <div className="ori-service-details-sidebar-widget-area">
		<div className="ori-service-details-widget ul-li-block">
			<div className="category-widget">
				<h3 className="widget-title wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">More Services</h3>
				<ul className="wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms">
					<li><NavLink activeClassName="active" to="website-development">Website Development</NavLink></li>
					<li><NavLink activeClassName="active" to="paid-marketing">Paid Marketing</NavLink></li>
					<li><NavLink activeClassName="active" to="search-engine-optimization">Search Engine Optimization</NavLink></li>
					<li><NavLink activeClassName="active" to="pay-per-click">Pay Per Click</NavLink></li>
					<li><NavLink activeClassName="active" to="social-media-presence">Social Media Presence</NavLink></li>
					<li><NavLink activeClassName="active" to="crm-automation">CRMs - Automation</NavLink></li>
					<li><NavLink activeClassName="active" to="email-marketing">Email Marketing</NavLink></li>
					<li><NavLink activeClassName="active" to="branding-strategies">Branding Strategies</NavLink></li>
				</ul>
			</div>
		</div>
		<div className="ori-service-details-widget ul-li-block">
			<div className="quote-widget">
				<h3 className="widget-title wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">Discuss Your Project</h3>
				<form method="post" className="wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms" name="info-form">
					<input type="text" name="Full Name" placeholder="Your Full Name" />
					<input type="email" name="Email ID" placeholder="Your Email ID" required />
					<input type="number" name="Phone No." placeholder="Your Phone No." required />
					<input type="hidden" name="Requirement" placeholder="Requirement" value="website-development" />
					<textarea name="Message" placeholder="Please Enter Your Requirement..."></textarea>
					<button className="ori-submit-btn1 text-uppercase" type="submit">submit now</button>
				</form>
			</div>
		</div>
	</div>
										
    </>
  )
}

export default Sidebar