import React, { useEffect, useRef } from "react";
import anime from "animejs";
import { Link } from "react-router-dom";
import BrandCarousel from "./BrandCarousel";
function Home() {
  const aboutRef = useRef(null);
  const slidervidRef = useRef(null);
  const introSecRef = useRef(null);
  const vid1Ref = useRef(null);
  const animeRef = useRef(null);
  const infoIntro = window.sessionStorage.getItem("info_intro");
  useEffect(() => {
    const intro_sec = introSecRef.current;
    if (infoIntro === "true") {
      if (
        intro_sec &&
        intro_sec.parentNode &&
        intro_sec.parentNode.contains(intro_sec)
      ) {
        // Check if the parent node still contains the element
        intro_sec.parentNode.removeChild(intro_sec);
      }
      slidervidRef && slidervidRef.current.play();
    } else {
      document.body.style.overflowY = "hidden";
      anime({
        targets: "#info__intro svg path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 2000,
        delay: function (el, i) {
          return i * 90;
        },
        direction: "alternate",
        loop: false,
      });
      setTimeout(function () {
        let fillStrk = document.getElementsByClassName("fill_strk");
        for (let item of fillStrk) {
          item.style.fill = "black";
        }
        setTimeout(function () {
          let animeVid = animeRef.current;
          vid1Ref.current && vid1Ref.current.play();
          if (animeVid && animeVid.classList.contains("anime__info")) {
            window.addEventListener("wheel", handleWheelClick);
            window.addEventListener("click", handleWheelClick);
          }
        }, 2000);
      }, 3000);
      window.sessionStorage.setItem("info_intro", "true");
    }

    const slideVid = slidervidRef.current;
    if (slideVid) {
      if (window.innerWidth < 480) {
        slideVid.play();
        document.body.style.overflowY = "visible";
      }
      window.addEventListener("scroll", function () {
        const scrollTop = window.pageYOffset;
        const about_sec = aboutRef.current;
        if (about_sec) {
          const aboutOffsetTop = about_sec.offsetTop;
          if (scrollTop > aboutOffsetTop) {
            slideVid.pause();
          } else if (scrollTop < aboutOffsetTop) {
            slideVid.play();
          }
        }
      });
    }

    function handleWheelClick() {
      let animeVid = animeRef.current;
      if (animeVid) {
        animeVid.classList.add("anime");
        animeVid.classList.remove("anime__info");
      }
      setTimeout(function () {
        document.body.style.overflowY = "visible";
        introSecRef.current && (introSecRef.current.style.display = "none");
        slidervidRef.current && slidervidRef.current.play();
      }, 2000);
      window.removeEventListener("wheel", handleWheelClick);
      window.removeEventListener("click", handleWheelClick);
    }
  }, []);

  const gotoAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <section
        id="ori-slider-1"
        className="ori-slider-section-1 position-relative"
      >
        <div
          className="home-banner-container l-full-width-container with-video"
          style={{ backgroundColor: "#423839" }}
        >
          <div className="full-bg-video-container">
            <div className="full-bg-video js-full-bg-video">
              <video
                className="byn z2 slider-video"
                ref={slidervidRef}
                loop="loop"
                muted="muted"
                poster="/assets/infodelix-sm.jpg"
                src={"/assets/infodelix-sm.mp4"}
                id="vid"
              ></video>
            </div>

            <div className="ori-slider-scroll position-absolute text-uppercase">
              <div className="scroll-mouse">
                {/* <img src={"/assets/muted.png"} id="vid_ctrl" style={{float: 'right', maxWidth: '50px', marginRight: '3%'}} alt="mute" /> */}
                <img
                  onClick={gotoAbout}
                  src={"/assets/img/hero/down-arrow.png"}
                  id="scroll_down_btn"
                  alt="scroll-down"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <section id="intro_sec" ref={introSecRef}>
          <div className="video">
            <div className="full-bg-video js-full-bg-video">
              <video
                className="byn z2"
                loop="loop"
                muted="muted"
                src={"/assets/info-intro.mp4"}
                ref={vid1Ref}
                id="vid1"
              />
            </div>
          </div>
          <div
            id="info__intro"
            ref={animeRef}
            className="info__wrap anime__info"
          >
            <button id="goto-web">Continue To Website</button>
            <div className="info__logo__wrap">
              <div className="info__logo__side info__logo__side--left" />
              <div className="info__logo__side info__logo__side--top" />
              <svg
                width={512}
                height={76}
                viewBox="0 0 512 76"
                fill="none"
                className="info__logo"
              >
                <mask id="mask">
                  <rect
                    fill="white"
                    width="100%"
                    height="100%"
                    fillOpacity={1}
                  />
                  <path
                    d="M20.4545 3.18182V73H1.5V3.18182H20.4545Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M89.8466 3.18182V73H74.0284L48.8011 36.3182H48.392V73H29.4375V3.18182H45.5284L70.3466 39.7273H70.892V3.18182H89.8466Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M98.8125 73V3.18182H147.903V18.4545H117.767V30.4545H144.903V45.7273H117.767V73H98.8125Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M222.511 38.0909C222.511 45.8636 221 52.4205 217.977 57.7614C214.955 63.0795 210.875 67.1136 205.739 69.8636C200.602 72.5909 194.875 73.9545 188.557 73.9545C182.193 73.9545 176.443 72.5795 171.307 69.8295C166.193 67.0568 162.125 63.0114 159.102 57.6932C156.102 52.3523 154.602 45.8182 154.602 38.0909C154.602 30.3182 156.102 23.7727 159.102 18.4545C162.125 13.1136 166.193 9.07954 171.307 6.35227C176.443 3.60227 182.193 2.22727 188.557 2.22727C194.875 2.22727 200.602 3.60227 205.739 6.35227C210.875 9.07954 214.955 13.1136 217.977 18.4545C221 23.7727 222.511 30.3182 222.511 38.0909ZM203.011 38.0909C203.011 33.9091 202.455 30.3864 201.341 27.5227C200.25 24.6364 198.625 22.4545 196.466 20.9773C194.33 19.4773 191.693 18.7273 188.557 18.7273C185.42 18.7273 182.773 19.4773 180.614 20.9773C178.477 22.4545 176.852 24.6364 175.739 27.5227C174.648 30.3864 174.102 33.9091 174.102 38.0909C174.102 42.2727 174.648 45.8068 175.739 48.6932C176.852 51.5568 178.477 53.7386 180.614 55.2386C182.773 56.7159 185.42 57.4545 188.557 57.4545C191.693 57.4545 194.33 56.7159 196.466 55.2386C198.625 53.7386 200.25 51.5568 201.341 48.6932C202.455 45.8068 203.011 42.2727 203.011 38.0909Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M258.145 73H231.281V3.18182H257.872C265.054 3.18182 271.259 4.57955 276.486 7.375C281.736 10.1477 285.781 14.1477 288.622 19.375C291.486 24.5795 292.918 30.8182 292.918 38.0909C292.918 45.3636 291.497 51.6136 288.656 56.8409C285.815 62.0455 281.793 66.0455 276.588 68.8409C271.384 71.6136 265.236 73 258.145 73ZM250.236 56.9091H257.463C260.918 56.9091 263.861 56.3523 266.293 55.2386C268.747 54.125 270.611 52.2045 271.884 49.4773C273.179 46.75 273.827 42.9545 273.827 38.0909C273.827 33.2273 273.168 29.4318 271.849 26.7045C270.554 23.9773 268.645 22.0568 266.122 20.9432C263.622 19.8295 260.554 19.2727 256.918 19.2727H250.236V56.9091Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M301.688 73V3.18182H352.006V18.4545H320.642V30.4545H349.415V45.7273H320.642V57.7273H351.869V73H301.688Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M361.406 73V3.18182H380.361V57.7273H408.588V73H361.406Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M435.205 3.18182V73H416.25V3.18182H435.205Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M464.506 3.18182L475.96 23.5H476.506L488.097 3.18182H509.233L488.369 38.0909L510.051 73H488.369L476.506 52.2727H475.96L464.097 73H442.551L463.96 38.0909L443.233 3.18182H464.506Z"
                    className="fill_strk"
                    fill="black"
                  />
                  <path
                    d="M20.4545 3.18182V73H1.5V3.18182H20.4545Z"
                    stroke="url(#paint0_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M89.8466 3.18182V73H74.0284L48.8011 36.3182H48.392V73H29.4375V3.18182H45.5284L70.3466 39.7273H70.892V3.18182H89.8466Z"
                    stroke="url(#paint1_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M98.8125 73V3.18182H147.903V18.4545H117.767V30.4545H144.903V45.7273H117.767V73H98.8125Z"
                    stroke="url(#paint2_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M222.511 38.0909C222.511 45.8636 221 52.4205 217.977 57.7614C214.955 63.0795 210.875 67.1136 205.739 69.8636C200.602 72.5909 194.875 73.9545 188.557 73.9545C182.193 73.9545 176.443 72.5795 171.307 69.8295C166.193 67.0568 162.125 63.0114 159.102 57.6932C156.102 52.3523 154.602 45.8182 154.602 38.0909C154.602 30.3182 156.102 23.7727 159.102 18.4545C162.125 13.1136 166.193 9.07954 171.307 6.35227C176.443 3.60227 182.193 2.22727 188.557 2.22727C194.875 2.22727 200.602 3.60227 205.739 6.35227C210.875 9.07954 214.955 13.1136 217.977 18.4545C221 23.7727 222.511 30.3182 222.511 38.0909ZM203.011 38.0909C203.011 33.9091 202.455 30.3864 201.341 27.5227C200.25 24.6364 198.625 22.4545 196.466 20.9773C194.33 19.4773 191.693 18.7273 188.557 18.7273C185.42 18.7273 182.773 19.4773 180.614 20.9773C178.477 22.4545 176.852 24.6364 175.739 27.5227C174.648 30.3864 174.102 33.9091 174.102 38.0909C174.102 42.2727 174.648 45.8068 175.739 48.6932C176.852 51.5568 178.477 53.7386 180.614 55.2386C182.773 56.7159 185.42 57.4545 188.557 57.4545C191.693 57.4545 194.33 56.7159 196.466 55.2386C198.625 53.7386 200.25 51.5568 201.341 48.6932C202.455 45.8068 203.011 42.2727 203.011 38.0909Z"
                    stroke="url(#paint3_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M258.145 73H231.281V3.18182H257.872C265.054 3.18182 271.259 4.57955 276.486 7.375C281.736 10.1477 285.781 14.1477 288.622 19.375C291.486 24.5795 292.918 30.8182 292.918 38.0909C292.918 45.3636 291.497 51.6136 288.656 56.8409C285.815 62.0455 281.793 66.0455 276.588 68.8409C271.384 71.6136 265.236 73 258.145 73ZM250.236 56.9091H257.463C260.918 56.9091 263.861 56.3523 266.293 55.2386C268.747 54.125 270.611 52.2045 271.884 49.4773C273.179 46.75 273.827 42.9545 273.827 38.0909C273.827 33.2273 273.168 29.4318 271.849 26.7045C270.554 23.9773 268.645 22.0568 266.122 20.9432C263.622 19.8295 260.554 19.2727 256.918 19.2727H250.236V56.9091Z"
                    stroke="url(#paint4_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M301.688 73V3.18182H352.006V18.4545H320.642V30.4545H349.415V45.7273H320.642V57.7273H351.869V73H301.688Z"
                    stroke="url(#paint5_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M361.406 73V3.18182H380.361V57.7273H408.588V73H361.406Z"
                    stroke="url(#paint6_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M435.205 3.18182V73H416.25V3.18182H435.205Z"
                    stroke="url(#paint7_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <path
                    d="M464.506 3.18182L475.96 23.5H476.506L488.097 3.18182H509.233L488.369 38.0909L510.051 73H488.369L476.506 52.2727H475.96L464.097 73H442.551L463.96 38.0909L443.233 3.18182H464.506Z"
                    stroke="url(#paint8_linear_2_3)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint6_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint7_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                    <linearGradient
                      id="paint8_linear_2_3"
                      x1={510}
                      y1="33.0001"
                      x2="1.00001"
                      y2="38.0001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.234375" stopColor="black" />
                      <stop offset="0.666667" stopColor="black" />
                    </linearGradient>
                  </defs>
                </mask>
                <rect
                  mask="url(#mask)"
                  fill="#000000"
                  width={1200}
                  height={600}
                  fillOpacity={1}
                />
              </svg>
              <div className="info__logo__side info__logo__side--right" />
              <div className="info__logo__side info__logo__side--bottom" />
            </div>
          </div>
        </section>
      </div>

      <section
        id="ori-agency-about"
        ref={aboutRef}
        className="ori-ageny-about-section light-md"
      >
        <div className="container">
          <div className="ori-agency-about-content">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="ori-section-title-1 text-uppercase wow fadeInLeft"
                  id="about-hd"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <h2>
                    <span>About </span> infodelix
                  </h2>
                </div>
                <div
                  className="ori-agency-section-title pera-content wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <p>
                    As a leading website development and design firm in the
                    industry, InfodeliX strives to be a multi-channel digital
                    marketing agency with extraordinary creativity in all
                    marketing-related activities.
                  </p>
                  <p>
                    We transform our client's business outcomes and drive
                    profitable growth for our partners by delivering the best
                    solutions in the industry.
                  </p>
                  <p>
                    Our objective is to create value for all our clients while
                    maintaining the brand ethos and carefully crafting tailor
                    made solutions for each client. We have a team of experts
                    with vast experience in website development who have been
                    delivering modern and technology led cutting-edge work to
                    cater to the specific requirements of our clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="ori-agency-about-img wow zoomIn"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms"
                >
                  <img
                    src={"/assets/img/inner-pages/infodelix-x.gif"}
                    alt="Infodelix"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="ori-feature-4"
        className="ori-feature-section-4 position-relative ori-team-content-area social-para"
      >
        <div className="container">
          <div className="ori-feature-content-4">
            <div className="row">
              <div className="col-lg-5">
                <div className="ori-feature-text-wrap-4">
                  <div
                    className="ori-section-title-4 text-uppercase wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                  >
                    <h2>Our Vision</h2>
                  </div>

                  <div
                    className="ori-feature-text-area-4 pera-content wow fadeInUp"
                    data-wow-delay="300ms"
                    data-wow-duration="1000ms"
                  >
                    <p>
                      We are passionate about our work. Our designers stay ahead
                      of the curve to provide engaging and user-friendly website
                      designs to make your business stand out.
                    </p>
                    <p>
                      Our developers are committed to maintaining the highest
                      web standards so that your site will withstand the test of
                      time. We care about your business.
                    </p>
                    <div className="ori-btn-2 text-uppercase">
                      <Link to="contact">Have a query?</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="ori-feature-item-wrapper-4">
                  <div className="row">
                    <div
                      className="col-md-6 wow fadeInUp"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                    >
                      <div className="ori-feature-item-4 d-flex">
                        <div className="ori-feature-icon d-flex justify-content-center align-items-center">
                          <i className="fal fa-pencil-ruler"></i>
                        </div>
                        <div className="ori-feature-text pera-content">
                          <h3>
                            Logo <br />
                            Design
                          </h3>
                          <p>
                            We design logo’s based on the brand’s ethos and
                            values in mind. Our team of designers create brand
                            logo’s which are eye catching and create brand
                            recall.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 wow fadeInUp"
                      data-wow-delay="300ms"
                      data-wow-duration="1000ms"
                    >
                      <div className="ori-feature-item-4 d-flex">
                        <div className="ori-feature-icon d-flex justify-content-center align-items-center">
                          <i className="fal fa-phone-laptop"></i>
                        </div>
                        <div className="ori-feature-text pera-content">
                          <h3>Website Development</h3>
                          <p>
                            We create websites based on the latest technology
                            along with cutting-edge designs to give a surreal
                            and seamless experience for users.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 wow fadeInUp"
                      data-wow-delay="400ms"
                      data-wow-duration="1000ms"
                    >
                      <div className="ori-feature-item-4 d-flex">
                        <div className="ori-feature-icon d-flex justify-content-center align-items-center">
                          <i className="fal fa-bullhorn"></i>
                        </div>
                        <div className="ori-feature-text pera-content">
                          <h3>Social Media Presence</h3>
                          <p>
                            We create and amplify a brand’s social media
                            presence by using effective social media strategies
                            and quirky communication.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 wow fadeInUp"
                      data-wow-delay="500ms"
                      data-wow-duration="1000ms"
                    >
                      <div className="ori-feature-item-4 d-flex">
                        <div className="ori-feature-icon d-flex justify-content-center align-items-center">
                          <i className="fal fa-file-search"></i>
                        </div>
                        <div className="ori-feature-text pera-content">
                          <h3>
                            Google <br />
                            Presence
                          </h3>
                          <p>
                            Through SEO and SEM we create a presence on Google
                            which is one of the most important media channels of
                            paid media.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="ori-service-page-service"
        className="ori-service-page-service-section social-para light-md"
      >
        <div className="container">
          <div className="ori-service-top-content-1 justify-content-between align-items-center">
            <div
              className="ori-section-title-1 text-center text-uppercase wow zoomIn"
              id="service-hd"
              data-wow-delay="200ms"
              data-wow-duration="1000ms"
            >
              <h2>
                <span>Our</span> Services
              </h2>
            </div>
          </div>
          <div className="ori-service-page-service-content">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <Link to="website-development">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fal fa-phone-laptop"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>Website Development</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="paid-marketing">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fal fa-bullhorn"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>Paid Marketing</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="search-engine-optimization">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fal fa-analytics"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>Search Engine Optimization</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="pay-per-click">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="500ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fab fa-product-hunt"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>Pay Per Click</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="social-media-presence">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="800ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fal fa-crop-alt"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>Social Media Presence</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="crm-automation">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="1100ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fab fa-joomla"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>CRMs - Automation</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="email-marketing">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="1200ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fal fa-envelope-open-text"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>Email Marketing</h3>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <Link to="branding-strategies">
                  <div
                    className="ori-service-page-service-inner-item services-bg wow fadeInUp"
                    data-wow-delay="1400ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="service-icon">
                      <i className="fal fa-drafting-compass"></i>
                    </div>
                    <div className="service-text pera-content">
                      <h3>Branding Strategies</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="ori-btn-1 text-uppercase wow fadeIn text-center mt-5"
            data-wow-delay="1500ms"
            data-wow-duration="1500ms"
          >
            <Link to="contact">Not able to find?</Link>
          </div>
        </div>
      </section>

      <section
        id="phrases-digital"
        className="ori-service-page-service-section phrases  social-para"
      >
        <div className="container-fluid">
          <div
            className="ori-section-title-1 wow fadeInLeft"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <h2 className="text-uppercase">
              <span>Being</span> Digital
            </h2>{" "}
            <br />
            <p>
              Our strategy team follows a holistic approach with extensive
              research using adtech tools to identify audience cohorts and map
              brand competition before presenting a stratagem to stand out and
              come up with innovative solutions. They are the brain behind the
              planning and ideation of the concept for the brief springing up
              with out-of-the-box concepts for the brands we work with.
            </p>{" "}
            <p>
              The ideas envisaged are then presented to the client, explaining
              the nuances and detailing behind every concept and how it
              resonates with the brand’s ethos.
            </p>{" "}
            <p>
              Media planning and execution are done by our skilled core team
              well-versed with the best media channels and cost-effective
              techniques for buying media, keeping the execution goal in mind
              and ensuring its delivery. Post-campaign results and analysis of
              campaign execution. Target v/s achieved and future
              recommendations.
            </p>
          </div>
          <div
            className="phrase wow fadeInRight text-right"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <img
              src={"/assets/img/inner-pages/digital-phrase.png"}
              alt="Digital Phases"
            />
          </div>
        </div>
      </section>

      <section
        id="approach"
        className="ori-feature-section-4 position-relative ori-team-content-area light-md"
      >
        <div className="container">
          <div className="ori-feature-content-4">
            <div className="row">
              <div className="col-lg-12 text-left">
                <div
                  className="ori-section-title-1 text-uppercase wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <h2 style={{ marginBottom: "40px" }}>
                    <span>Our</span> Approach
                  </h2>
                </div>
                <img
                  src={"/assets/img/inner-pages/svg/approach.svg"}
                  className="approach wow fadeInRight"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                />
                <p
                  className="wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  We are a 360-degree digital marketing agency with expertise in
                  All Things Digital- from Creative Designing and Content
                  Creation, all media channels from SEO to Performance
                  Marketing, creating content and also amplifying it using
                  social media, and paid media. Our extraordinary in-house team
                  of enthusiasts has capabilities across all these verticals as
                  we focus on giving our clients a one-stop digital marketing
                  suite. Our core focus is on delivering value to all our
                  clients and becoming their partner in digital transformation.
                </p>
                <p
                  className="wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  We are a blend of Creative Dreamers and Rational Thinkers who
                  will continue helping brands create conversations that are
                  inspiring, engaging, and rewarding.
                </p>
                <p
                  className="wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  Our goal is to become a leading digital marketing agency in
                  the near future that is driven by its core values and
                  brand-centric approach. Also, our endeavor is to keep
                  learning, growing, and adapting to the dynamic digital
                  landscape by adopting new technology, new avenues and keep
                  evolving as we continue to grow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="phrases-web"
        className="ori-service-page-service-section phrases social-para "
      >
        <div className="container-fluid">
          <div
            className="phrase wow fadeInLeft text-left"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <img src={"/assets/img/inner-pages/web-phrase.png"} />
          </div>
          <div
            className="ori-section-title-1 wow fadeInRight"
            data-wow-delay="400ms"
            data-wow-duration="1500ms"
          >
            <h2 className="text-uppercase">
              <span>Web</span> Development
            </h2>{" "}
            <br />
            <p>
              Our core strategy team undertakes extensive research regarding
              templates, technology, server integrations, safety and security,
              design, etc., to emerge with the best ideas and solutions for
              website creation. Thereby a strategy is created envisaging the
              website design, technology, and implementation, along with
              expected timelines.
            </p>
            <p>
              The next crucial phase is presenting the strategy and analysing
              the SOW- execution, the required man-hours, design, servers, and
              budgeting. The creators weave their magic and create the website
              pages using the latest technology and software keeping the brand's
              guidelines and ethos in mind. The design work is regularly shared
              and changes are implemented based on feedback. Once it’s ready,
              coding is done to implement and marry the design with the logic
              for creating a smooth functioning website.
            </p>
            <p>
              Now rigorous testing of the website is done on different devices,
              locations, and all the features are tested using our standard
              check and balance procedures to have a smooth and bug-free running
              website. Once the website is live, regular maintenance is required
              to check for server issues, bugs, developments, changes, and other
              implementations. Our proficient tech and support team handles
              websites with large-scale traffic and advanced technology to
              monitor thereby ensuring the website's performance as per the
              expectation.
            </p>
          </div>
        </div>
      </section>

      <section
        id="ori-testimonial-1"
        className="ori-testimonial-section-1 social-para position-relative"
        style={{ backgroundImage: `url(/assets/img/portfolio-bg.jpg)` }}
      >
        <div className="container">
          <div className="ori-testimonial-content-1 text-center position-relative">
            <h3
              className="wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              The best online portfolios radiate creative vision and personality
            </h3>
            <div
              className="ori-btn-2 text-uppercase mt-5 wow fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <Link
                to="portfolio"
                style={{ background: "linear-gradient(45deg,#0178e4,#00f152)" }}
              >
                Our Portfolio
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section
        id="ori-testimonial-1"
        className="ori-testimonial-section-1 social-para position-relative"
      >
        <div className="container-fluid">
          <div className="ori-testimonial-content-1 position-relative">
            <div
              className="ori-section-title-1 text-center text-uppercase wow zoomIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <h2>
                <span>Client's </span> Feedback
              </h2>
            </div>
            <div className="feedback">
              <div
                className="text-center text-uppercase wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <img src={"/assets/img/inner-pages/client1.png"} />
              </div>
              <div
                className="text-center text-uppercase wow fadeInUp"
                data-wow-delay="1200ms"
                data-wow-duration="1500ms"
              >
                <img src={"/assets/img/inner-pages/client2.png"} />
              </div>
              <div
                className="text-center text-uppercase wow fadeInUp"
                data-wow-delay="2200ms"
                data-wow-duration="1500ms"
              >
                <img src={"/assets/img/inner-pages/client3.png"} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="ori-sponsor-1"
        className="ori-sponsor-section-1 position-relative light-md"
      >
        <div className="container">
          <div
            className="ori-section-title-1 text-center text-uppercase wow zoomIn"
            data-wow-delay="400ms"
            data-wow-duration="1500ms"
          >
            <h2>
              <span>Our </span> Clients
            </h2>
          </div>

          <BrandCarousel />
        </div>
      </section>
    </>
  );
}
export default Home;
