import React from 'react'

const Contact = () => {
  return (
    <>
    <section id="ori-breadcrumbs" className="ori-breadcrumbs-section contact-bg position-relative" style={{backgroundImage: "url('/assets/img/bg/bread-bg.png')"}}>
    <div className="container">
        <div className="ori-breadcrumb-content text-center ul-li  wow fadeIn"
        data-wow-delay="200ms"
        data-wow-duration="1500ms">
            <h1><span>Contact Us</span></h1>
        </div>
    </div>
</section>	

<section id="ori-agency-contact" className="ori-agency-contact-section social-para">
		<div className="container">
			<div className="ori-agency-contact-content">
				
				<div className="row">
					<div className="col-lg-6">
						<div className="ori-agency-contact-text">
							<div className="ori-agency-contact-title wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '200ms', animationName: 'fadeInUp'}}>
					<h3>Get In Touch</h3>
				</div>
							<div className="ori-agency-contact-info wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '200ms', animationName: 'fadeInUp'}}>
								<div className="info-item-area d-flex align-items-center">
									<div className="inner-icon d-flex align-items-center justify-content-center">
										<i className="fas fa-map-marker-alt"></i>
									</div>
									<div className="inner-text">
										<h3>Our head office address:</h3>
										<span>A-41, 18th floor, Iconic Corenthum, Sector 62, Noida, UP 201309, India</span>
									</div>
								</div>
								<div className="info-item-area d-flex align-items-center">
									<div className="inner-icon d-flex align-items-center justify-content-center">
										<i className="fas fa-phone-alt"></i>
									</div>
									<div className="inner-text">
										<h3>Call for help:</h3>
										<span><a href="tel:8860650720"> +91 88606 50720</a></span>
									</div>
								</div>
								<div className="info-item-area d-flex align-items-center">
									<div className="inner-icon d-flex align-items-center justify-content-center">
										<i className="fas fa-envelope"></i>
									</div>
									<div className="inner-text">
										<h3>Mail us for information</h3>
										<span><a href="mailto:contact@infodelix.com">contact@infodelix.com</a></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="ori-agency-contact-form-area">
							<div className="ori-agency-contact-form wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '200ms', animationName: 'fadeInUp'}}>
								<form action="email-sender.php" method="post" name="info-form">
									<div className="row">
										<div className="col-md-6">
											<label><i className="fas fa-user"></i> Full Name</label>
											<input type="text" name="Full Name" placeholder="John Snow" />
										</div>
										<div className="col-md-6">
											<label><i className="fas fa-envelope"></i> Email *</label>
											<input type="email" name="Email ID" placeholder="info@example.com" required />
										</div>
										<div className="col-md-12">
											<label><i className="fas fa-phone-alt"></i> Phone *</label>
											<input type="text" name="Phone No." placeholder="+91 1212 121 121" required />
											<input type="hidden" name="Requirement" placeholder="Requirement" value="Contact Form" />
										</div>
										<div className="col-md-12">
											<label><i className="fas fa-pencil-alt"></i> Message</label>
											<textarea name="Message" placeholder="Your Requirement..."></textarea>
										</div>
										{/* <div className="col-md-12">
											<div className="g-recaptcha d-flex justify-content-center pb-3" data-sitekey="6LdHgB0kAAAAAJCxD9CwohiwjMOEj17xE1UPKDO2"></div>
										</div> */}
										<div className="col-md-12">
											<button type="submit">Submit Now!</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>	

    <div className="ori-google-map">
		<iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0908313529653!2d77.37160421503617!3d28.627039682419944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5d27043a5d5%3A0x634181ebaffb660e!2sInfodeliX!5e0!3m2!1sen!2sin!4v1670991033962!5m2!1sen!2sin"} height="500" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
	</div>

</>
  )
}

export default Contact