import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'
const PaidMarketing = () => {
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section paid-bg position-relative"
    style={{ backgroundColor: "#f45107", backgroundImage: 'url("assets/img/headers/paid.jpg")' }}
  >
    <div className="container">
      <span
        className="buttons prev-service wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="website-development">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
      <div
        className="ori-breadcrumb-content text-right ul-li wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>Paid</span>
          <br />
          Marketing
        </h1>
      </div>
      <span
        className="buttons next-service wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="search-engine-optimization">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
    </div>
  </section>
  <section
    id="ori-service-details"
    className="ori-service-details-section position-relative"
    style={{
      backgroundImage: 'url("assets/img/inner-pages/bg-home.png")',
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000000c2",
      backgroundBlendMode: "overlay"
    }}
  >
    <div className="container">
      <div className="ori-service-details-content-wrapper">
        <div className="row">
          <div className="col-lg-8">
            <div className="ori-service-details-content">
              <div className="ori-about-play-area position-relative">
                <div
                  className="ori-about-play-img wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <img
                    src="assets/img/inner-pages/svg/paid-marketing.svg"
                    alt="Paid Marketing InfodeliX"
                  />
                </div>
              </div>
              <div
                className="ori-service-details-text pera-content wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1200ms"
              >
                <p>
                  SEM or Paid Search is how you ensure that your product or
                  service can be easily found. It is a pull-based source of
                  advertising where the user is looking for a product and
                  service and as a brand one needs to be present on the search
                  engines. It is always one source of digital media promotion
                  because a user before buying a product or service is searching
                  for it online.
                </p>
                <p>
                  The constant process of how we search, shop, and buy products
                  online demands a deep level of understanding and technology
                  such as voice search and other big conglomerates, as customers
                  have new choices. Staying ahead of customer habits drives
                  competitive advantage for any brand.
                </p>
                <p>
                  We unlock the power of Search Engine Marketing by using our
                  SEM team’s vast expertise and modern technology solutions to
                  engage our brand’s potential customers with highly relevant
                  messaging as they search across devices.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default PaidMarketing