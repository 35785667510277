import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
        <footer id="ori-footer" className="ori-footer-section social-para footer-style-one">
		<div className="container">
			<div className="ori-footer-title text-center text-uppercase wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
				<h2><Link to="contact">Get In <span>Touch</span></Link></h2>
			</div>
			<div className="ori-footer-widget-wrapper">
				<div className="row">
					<div className="col-lg-4 col-md-6">
						<div className="ori-footer-widget m-n">
							<div className="logo-widget wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
								<Link to="/"><img src={"/assets/img/logo/infodelix-logo.svg"} width="210" alt=""/></Link>
								<p>As a leading website development and design firm in India , InfodeliX has established and is still growing a multi-channel digital marketing agency with exceptional creativity in all things marketing-related. Our aim is to realise the client's objectives and aspirations while delivering bottom-line results and superior ROI. We do this through industry-leading digital design and development, optimised social media strategies, and successful PR campaigns.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-2 col-md-6">
						<div className="ori-footer-widget">
							<div className="menu-location-widget ul-li-block">
								<h2 className="widget-title text-uppercase wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">Quick Links</h2>
								<ul>
									<li className=" wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms"><Link to="/#about-hd"><i className="fa fa-angle-double-right"></i> About us</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms"><Link to="/#service-hd"><i className="fa fa-angle-double-right"></i> Services</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms"><Link to="portfolio"><i className="fa fa-angle-double-right"></i> Portfolio</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms"><Link to="/#ori-sponsor-1"><i className="fa fa-angle-double-right"></i> Our Clients</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms"><Link to="contact"><i className="fa fa-angle-double-right"></i> Contact us</Link></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="ori-footer-widget">
							<div className="menu-location-widget ul-li-block">
								<h2 className="widget-title text-uppercase wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">Our Services</h2>
								<ul>
									<li className=" wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms"><Link to="website-development"><i className="fa fa-angle-double-right"></i> Website Development</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms"><Link to="paid-marketing"><i className="fa fa-angle-double-right"></i> Paid Marketing</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms"><Link to="search-engine-optimization"><i className="fa fa-angle-double-right"></i> Search Engine Optimization</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms"><Link to="pay-per-click"><i className="fa fa-angle-double-right"></i> Pay Per Click</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms"><Link to="social-media-presence"><i className="fa fa-angle-double-right"></i> Social Media Presence</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="1500ms"><Link to="crm-automation"><i className="fa fa-angle-double-right"></i> CRMs - Automation</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms"><Link to="email-marketing"><i className="fa fa-angle-double-right"></i> Email Marketing</Link></li>
									<li className=" wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms"><Link to="branding-strategies"><i className="fa fa-angle-double-right"></i> Branding Strategies</Link></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="ori-footer-widget">
							<div className="contact-widget ul-li-block">
								<h2 className="widget-title text-uppercase wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">Contact info</h2>
								<div className="contact-info">
									<span className=" wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms"><i className="fa fa-home"></i> A-41, 18th floor, Iconic Corenthum, Sector 62, Noida, UP 201309, India</span>
									<a className=" wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms" href="tel:8860650720"><i className="fa fa-mobile"></i> +91 88606 50720</a>
									<a className=" wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms" href="mailto:contact@infodelix.com"><i className="fa fa-envelope"></i> contact@infodelix.com</a>
									<span className=" wow fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms"><i className="fa fa-clock"></i> Office Hours: 8AM - 5PM</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ori-footer-copyright d-flex justify-content-between">
				<div className="ori-copyright-text">
					© 2022 All Rights Reserved | <a href="privacy_policy.html" target='_bank'>Privacy Policy</a>
				</div>
				<div className="ori-copyright-social">
					<a href="https://www.facebook.com/profile?id=100087787571579" target="_blank"><i className="fab fa-facebook-f"></i></a>
					<a href="https://www.linkedin.com/company/infodelix/" target="_blank"><i className="fab fa-linkedin"></i></a>
					<a href="https://www.instagram.com/infodelix/" target="_blank"><i className="fab fa-instagram"></i></a>
					<a href="https://www.youtube.com/watch?v=mNzahNr-cN8" target="_blank"><i className="fab fa-youtube"></i></a>
				</div>
			</div>
		</div>
	</footer>
    </>
  )
}
