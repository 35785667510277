import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'

const CRM = () => {
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section position-relative"
    style={{ backgroundColor: "#323266", backgroundImage: 'url("assets/img/headers/crm.jpg")' }}
  >
    <div className="container">
      <span
        className="buttons prev-service wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="social-media-presence">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
      <div
        className="ori-breadcrumb-content text-right ul-li wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>CRM</span> <br />
          Automation
        </h1>
      </div>
      <span
        className="buttons next-service wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="email-marketing">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
    </div>
  </section>
  {/* End of Breadcrumbs section
	============================================= */}
  {/* Start of Service Details  section
	============================================= */}
  <section
    id="ori-service-details"
    className="ori-service-details-section position-relative"
    style={{
      backgroundImage: 'url("assets/img/inner-pages/bg-home.png")',
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000000c2",
      backgroundBlendMode: "overlay"
    }}
  >
    <div className="container">
      <div className="ori-service-details-content-wrapper">
        <div className="row">
          <div className="col-lg-8">
            <div className="ori-service-details-content">
              <div className="ori-about-play-area position-relative">
                <div
                  className="ori-about-play-img wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <img
                    src="assets/img/inner-pages/svg/crm.svg"
                    alt="CRMs - Automation InfodeliX"
                  />
                </div>
              </div>
              <div
                className="ori-service-details-text pera-content wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1200ms"
              >
                <p>
                  Customer relationship management or CRM, is a technology for
                  managing all customer and user interactions. This system helps
                  in automating customers, streamlining work processes, and
                  boosting revenues.
                </p>
                <p>
                  With a professional CRM, it’s much easier and faster to
                  generate and manage leads, communicate with existing
                  customers, build trust, and provide qualified support. With
                  our in-house tech team and robust CRM management process, we
                  help drive seamless and completely automated CRM solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default CRM