import React, { useState, useRef } from 'react';

function ImageComponent({ src }) {
  const [bottom, setBottom] = useState(0);

  function handleMouseOver(event) {
    setBottom(`${event.target.height - 410}px`);
  }

  function handleMouseLeave() {
    setBottom('0px');
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src={src}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      style={{ bottom: bottom }}
    />
  );
}

const Portfolio = () => {
  const videoRef = useRef(null);

  function handleMouseOver() {
    videoRef.current.play();
  }

  function handleMouseLeave() {
    videoRef.current.pause();
  }
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section portfolio-bg position-relative"
    style={{ backgroundColor: "#1b212d", backgroundImage: ('url('+"/assets/img/headers/portfolio.jpg)") }}
  >
    <div className="container">
      <div
        className="ori-breadcrumb-content text-right ul-li wow fadeInRight"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>Our</span>
          <br />
          Portfolio
        </h1>
      </div>
    </div>
  </section>
  <section
    id="ori-portfolio-feed"
    className="ori-portfolio-feed-section position-relative social-para"
  >
    <div className="container">
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link wow fadeInUp active"
            id="pills-brands-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-brands"
            type="button"
            role="tab"
            aria-controls="pills-brands"
            aria-selected="true"
            data-wow-delay="300ms"
            data-wow-duration="1200ms"
          >
            Website Design
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link wow fadeInUp"
            id="pills-website-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-website"
            type="button"
            role="tab"
            aria-controls="pills-website"
            aria-selected="false"
            data-wow-delay="400ms"
            data-wow-duration="1200ms"
          >
            Logo Design
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link wow fadeInUp"
            id="pills-video-graphic-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-video-graphic"
            type="button"
            role="tab"
            aria-controls="pills-video-graphic"
            aria-selected="false"
            data-wow-delay="500ms"
            data-wow-duration="1200ms"
          >
            Video Graphics
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link wow fadeInUp"
            id="pills-social-media-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-social-media"
            type="button"
            role="tab"
            aria-controls="pills-social-media"
            aria-selected="false"
            data-wow-delay="600ms"
            data-wow-duration="1200ms"
          >
            Social media presence
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-brands"
          role="tabpanel"
          aria-labelledby="pills-brands-tab"
          tabIndex={0}
        >
          <div className="row ori-portfolio-feed-item-wrapper filtr-container">
            <div
              className="col-lg-4 col-sm-6 filtr-item websites"
              data-category={2}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <ImageComponent
                    src={"/assets/img/portfolio/sits.jpg"}
                    alt="Step Into Training Services"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item websites"
              data-category={2}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <ImageComponent
                    src={"/assets/img/portfolio/aspect.jpg"}
                    alt="Aspect Migration"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item websites"
              data-category={2}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <ImageComponent
                    src={"/assets/img/portfolio/riverdale.jpg"}
                    alt="Riverdale Institute"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item websites"
              data-category={2}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <ImageComponent
                    src={"/assets/img/portfolio/getmyqual.jpg"}
                    alt="Get My Qual"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item websites"
              data-category={2}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <ImageComponent
                    src={"/assets/img/portfolio/aet.jpg"}
                    alt="Advanced Education & Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item websites"
              data-category={2}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <ImageComponent
                    src={"/assets/img/portfolio/kaltraining.jpg"}
                    alt="Kal Training"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-website"
          role="tabpanel"
          aria-labelledby="pills-website-tab"
          tabIndex={0}
        >
          <div className="row ori-portfolio-feed-item-wrapper filtr-container">
            <div
              className="col-lg-4 col-sm-6 filtr-item brands filteredOut"
              data-category={1}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img src={"/assets/img/portfolio/brand-1.jpg"} alt="SITS" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item brands filteredOut"
              data-category={1}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/brand-2.jpg"}
                    alt="Riverdale Institute"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item brands filteredOut"
              data-category={1}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/brand-3.jpg"}
                    alt="Kal Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item brands filteredOut"
              data-category={1}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/brand-5.jpg"}
                    alt="Advanced Education & Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item brands filteredOut"
              data-category={1}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/brand-4.jpg"}
                    alt="Get My Qual"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-video-graphic"
          role="tabpanel"
          aria-labelledby="pills-video-graphic-tab"
          tabIndex={0}
        >
          <div className="row ori-portfolio-feed-item-wrapper filtr-container">
            <div
              className="col-lg-4 col-sm-6 filtr-item video-graphics filteredOut"
              data-category={3}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <video
                    className="byn z2 slider-video"
                    src={"/assets/riverdale-institute.mp4"}
                    poster="assets/river-thumb.jpg"
                    id="vid2"
                    width="100%"
                    ref={videoRef}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-social-media"
          role="tabpanel"
          aria-labelledby="pills-social-media-tab"
          tabIndex={0}
        >
          <div className="row ori-portfolio-feed-item-wrapper filtr-container">
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-1.jpg"}
                    alt="Aspect Migration"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img src={"/assets/img/portfolio/smp-2.jpg"} alt="WLSC" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-3.jpg"}
                    alt="Aspect Migration"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-4.jpg"}
                    alt="Advanced Education & Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-5.jpg"}
                    alt="Advanced Education & Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-6.jpg"}
                    alt="Kal Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-7.jpg"}
                    alt="Kal Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-8.jpg"}
                    alt="Kal Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-9.jpg"}
                    alt="Kal Training"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-10.jpg"}
                    alt="Aspect Migration"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 filtr-item filteredOut"
              data-category={4}
              data-sort="Busy streets"
            >
              <div className="ori-portfolio-item position-relative">
                <div className="portfolio-img">
                  <img
                    src={"/assets/img/portfolio/smp-11.jpg"}
                    alt="Step Into Training Services"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Portfolio