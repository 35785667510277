import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'
const SMP = () => {
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section smp-bg position-relative"
    style={{ backgroundImage: 'url(assets/img/headers/smp.jpg), linear-gradient(45deg, #fbfcff, #4187cf)' }}
  >
    <div className="container">
      <span
        className="buttons prev-service wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="pay-per-click">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
      <div
        className="ori-breadcrumb-content text-left ul-li wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>Social Media</span>
          <br />
          Presence
        </h1>
      </div>
      <span
        className="buttons next-service wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="crm-automation">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
    </div>
  </section>
  {/* End of Breadcrumbs section
	============================================= */}
  {/* Start of Service Details  section
	============================================= */}
  <section
    id="ori-service-details"
    className="ori-service-details-section position-relative"
    style={{
      backgroundImage: 'url("assets/img/inner-pages/bg-home.png")',
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000000c2",
      backgroundBlendMode: "overlay"
    }}
  >
    <div className="container">
      <div className="ori-service-details-content-wrapper">
        <div className="row">
          <div className="col-lg-8">
            <div className="ori-service-details-content">
              <div className="ori-about-play-area position-relative">
                <div
                  className="ori-about-play-img wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <img
                    src="assets/img/inner-pages/svg/smp.svg"
                    alt="Social Media Presence InfodeliX"
                  />
                </div>
              </div>
              <div
                className="ori-service-details-text pera-content wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1200ms"
              >
                <p>
                  Social media management can consume a huge amount of time, and
                  people can get easily distracted by ‘feel good’ engagement
                  which doesn’t really drive brand goals.{" "}
                </p>
                <p>
                  Our team will use best practices and tools to make sure no
                  time is wasted.{" "}
                </p>
                <p>
                  Tools we use help us to quickly create and curate content and
                  schedule it to make sure our social networks are filled with
                  compelling posts across Social Media destinations like
                  Facebook, Instagram, Twitter, etc.
                </p>
                <div
                  className="ori-service-details-process ul-li-block wow fadeInUp"
                  data-wow-delay="400ms"
                  data-wow-duration="1200ms"
                >
                  <h2>Service Process</h2>
                  <div className="ori-service-process-item-wrap d-flex flex-wrap">
                    <div className="ori-service-process-item pera-content">
                      <h3>
                        <span>01</span> Grow social following and fan base
                      </h3>
                      <p>
                        Increase in social media presence will lead to a larger
                        growing fan base and greater reach for social media
                        activities. It will increase the potential for virality
                        to occur on popular posts, thus exponentially increasing
                        the audience for our messages.
                      </p>
                      <p>
                        <i
                          className="fa fa-chevron-right"
                          style={{ marginRight: 10 }}
                        />{" "}
                        We will create posts, marketing call-to-actions, and
                        other ways to encourage fans to follow and like you.
                      </p>
                    </div>
                    <div className="ori-service-process-item pera-content">
                      <h3>
                        <span>02</span> Increase engagement
                      </h3>
                      <p>
                        A high-quality, targeted fan base will be open to
                        engaging with you, but finding the right types of posts
                        and carefully planning an appropriate mix of promotional
                        versus informative posts will be critical to increasing
                        engagement.
                      </p>
                      <p>
                        <i
                          className="fa fa-chevron-right"
                          style={{ marginRight: 10 }}
                        />{" "}
                        We will create and curate content that is engaging to
                        your fans and ask them to engage.
                      </p>
                    </div>
                    <div className="ori-service-process-item pera-content">
                      <h3>
                        <span>03</span> Convert fans into supporters
                      </h3>
                      <p>
                        Sometimes fans aren’t ready to endorse you just yet, but
                        moving them along in your communication funnel is your
                        goal. Encouraging fans to register for a blog, free
                        download, or event can grow your fans into supporters
                        and increase your options for contacting them later.
                      </p>
                      <p>
                        <i
                          className="fa fa-chevron-right"
                          style={{ marginRight: 10 }}
                        />{" "}
                        We will promote your support campaign pages and links on
                        social media.
                      </p>
                    </div>
                    <div className="ori-service-process-item pera-content">
                      <h3>
                        <span>04</span> Get Votes
                      </h3>
                      <p>
                        Converting a social fan to a voter is our ultimate goal,
                        and all of our activities are designed to move customers
                        down that path. Special engagement on social media can
                        lead to immediate conversion to voters. Better yet, when
                        people share their opinions, the support can snowball.
                      </p>
                      <p>
                        <i
                          className="fa fa-chevron-right"
                          style={{ marginRight: 10 }}
                        />{" "}
                        We will promote customized messaging under all
                        established legal purviews that lead to votes, and where
                        possible, track votes resulting from social activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default SMP