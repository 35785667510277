import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'

const BrandingStrategies = () => {
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section branding-bg position-relative"
    style={{ backgroundImage: 'url("assets/img/headers/branding.jpg"), linear-gradient(45deg, #1c1c1c, white)' }}
  >
    <div className="container">
      <span
        className="buttons prev-service wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="email-marketing">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
      <div
        className="ori-breadcrumb-content text-right ul-li wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>Branding</span> <br />
          Strategies
        </h1>
      </div>
      <span
        className="buttons next-service wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="website-development">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
    </div>
  </section>


  <section
    id="ori-service-details"
    className="ori-service-details-section position-relative"
    style={{
      backgroundImage: 'url("assets/img/inner-pages/bg-home.png")',
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000000c2",
      backgroundBlendMode: "overlay"
    }}
  >
    <div className="container">
      <div className="ori-service-details-content-wrapper">
        <div className="row">
          <div className="col-lg-8">
            <div className="ori-service-details-content">
              <div className="ori-about-play-area position-relative">
                <div
                  className="ori-about-play-img wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <img
                    src="assets/img/inner-pages/svg/branding.svg"
                    alt="Branding Strategies InfodeliX"
                  />
                </div>
              </div>
              <div
                className="ori-service-details-text pera-content wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <p>
                  Branding has evolved over a period of time and moved towards
                  automated media buying platforms today. However, it is not
                  easy to create data-led strategies along with technology to
                  deliver the right target audience for any brand.
                </p>
                <p>
                  As a brand, all digital channels need to be interconnected in
                  a funnel approach (social, video, mobile, and display) to gain
                  the reach a brand needs. A brand needs to be present on
                  multiple platform-based audience-buying exchanges to capture
                  market share and get the right audience.
                </p>
                <p>
                  The desired results come with insights: using data to create
                  audience media preferences and drive media strategy. Then
                  there’s precision: knowing which audiences to target and
                  across which channels.{" "}
                </p>
                <p>
                  We help you deliver high-performing brand campaigns by
                  focusing on performance. Using technology, media planning, and
                  buying skills, our team of data analysts plans and execute the
                  brand campaigns as per the brand’s objective.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default BrandingStrategies