import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'

const EmailMarketing = () => {
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section email-bg position-relative"
    style={{ backgroundImage: 'url("assets/img/headers/email.jpg"), linear-gradient(45deg, #ccedfc, transparent)' }}
  >
    <div className="container">
      <span
        className="buttons prev-service wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="crm-automation">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
      <div
        className="ori-breadcrumb-content text-left ul-li wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>Email</span>
          <br />
          Marketing
        </h1>
      </div>
      <span
        className="buttons next-service wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="branding-strategies">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
    </div>
  </section>
  {/* End of Breadcrumbs section
	============================================= */}
  {/* Start of Service Details  section
	============================================= */}
  <section
    id="ori-service-details"
    className="ori-service-details-section position-relative"
    style={{
      backgroundImage: 'url("assets/img/inner-pages/bg-home.png")',
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000000c2",
      backgroundBlendMode: "overlay"
    }}
  >
    <div className="container">
      <div className="ori-service-details-content-wrapper">
        <div className="row">
          <div className="col-lg-8">
            <div className="ori-service-details-content">
              <div className="ori-about-play-area position-relative">
                <div
                  className="ori-about-play-img wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <img
                    src="assets/img/inner-pages/svg/email-marketing.svg"
                    alt="Email Marketing InfodeliX"
                  />
                </div>
              </div>
              <div
                className="ori-service-details-text pera-content wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1200ms"
              >
                <p>
                  Email marketing is a method of push advertising which is
                  generally used as one of the most common ways of Affiliate
                  Marketing. Using HTML creatives, a user receives promotional
                  content on their personal email id which they consume. It is
                  one of the most cost-effective ways of digital promotion and
                  is used extensively across brands.
                </p>
                <p>
                  {" "}
                  We provide targeted email marketing solutions using data and
                  user affinity to target the precise audience which is required
                  by a brand.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default EmailMarketing