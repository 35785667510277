import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if(hash && pathname === '/'){
      let t_el = document.querySelector(hash);
      if(t_el){
        window.scrollTo(t_el.offsetTop, t_el.offsetTop);
      }else{
        window.scrollTo(0, 0);
      }
    }else{
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
}

export default ScrollToTop;
