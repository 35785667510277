import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'
const SEO = () => {
  return (
    <>
  <section
    id="ori-breadcrumbs"
    className="ori-breadcrumbs-section seo-bg position-relative"
    style={{backgroundColor: "#d04b2a", backgroundImage: 'url("assets/img/headers/seo.jpg")' }}
  >
    <div className="container">
      <span
        className="buttons prev-service wow fadeInRight"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="paid-marketing">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
      <div
        className="ori-breadcrumb-content text-left ul-li wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <h1>
          <span>Search Engine</span>
          <br />
          Optimization
        </h1>
      </div>
      <span
        className="buttons next-service wow fadeInLeft"
        data-wow-delay="500ms"
        data-wow-duration="1500ms"
      >
        <Link to="pay-per-click">
          <img src={"assets/img/hero/down-arrow.png"} />
        </Link>
      </span>
    </div>
  </section>
  {/* End of Breadcrumbs section
	============================================= */}
  {/* Start of Service Details  section
	============================================= */}
  <section
    id="ori-service-details"
    className="ori-service-details-section position-relative"
    style={{
      backgroundImage: 'url("assets/img/inner-pages/bg-home.png")',
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000000c2",
      backgroundBlendMode: "overlay"
    }}
  >
    <div className="container">
      <div className="ori-service-details-content-wrapper">
        <div className="row">
          <div className="col-lg-8">
            <div className="ori-service-details-content">
              <div className="ori-about-play-area position-relative">
                <div
                  className="ori-about-play-img wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <img
                    src="assets/img/inner-pages/svg/seo.svg"
                    alt="Search Engine Optimization InfodeliX"
                  />
                </div>
              </div>
              <div
                className="ori-service-details-text pera-content wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1200ms"
              >
                <p>
                  Search Engine Optimisation (SEO), the way to increase the
                  organic traffic on a website is an evolving and ever-changing
                  mode of digital media promotion. With new technology and
                  platforms, voice search, mobile devices, and lighter websites
                  come new and unexpected challenges. Expertise in technical SEO
                  is not optional, and content strategy is completely dynamic.
                </p>
                <p>
                  Organic growth now depends upon the ability to adapt to
                  solutions.
                </p>
                <p>
                  Digital content is the base for all online customer
                  engagement. Our SEO services go beyond optimizing for searched
                  keywords to instead optimizing the experience for your brand’s
                  online audience using On page and Off page optimization
                  strategies as a part of the SEO service.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default SEO