import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BrandCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="ori-sponsor-img">
        <img src={"/assets/img/brands/aet.png"} alt="" />
      </div>
      <div className="ori-sponsor-img">
        <img src={"/assets/img/brands/aspect.png"} alt="" />
      </div>
      <div className="ori-sponsor-img">
        <img src={"/assets/img/brands/sits.png"} alt="" />
      </div>
      <div className="ori-sponsor-img">
        <img src={"/assets/img/brands/wlsc.png"} alt="" />
      </div>
      <div className="ori-sponsor-img">
        <img src={"/assets/img/brands/saddle.png"} alt="" />
      </div>
      <div className="ori-sponsor-img">
        <img src={"/assets/img/brands/stark.png"} alt="" />
      </div>
      <div className="ori-sponsor-img">
        <img src={"/assets/img/brands/angelic.png"} alt="" />
      </div>
    </Slider>
  );
};

export default BrandCarousel;
