import './App.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';

import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Contact from './Components/Contact';
import WebsiteDevelopment from './Components/services/WebsiteDevelopment';
import PaidMarketing from './Components/services/PaidMarketing';
import CRM from './Components/services/CRM';
import BrandingStrategies from './Components/services/BrandingStrategies';
import EmailMarketing from './Components/services/EmailMarketing';
import PPC from './Components/services/PPC';
import SEO from './Components/services/SEO';
import SMP from './Components/services/SMP';
import Portfolio from './Components/Portfolio';
function App() {
  return (
    <Router>
      <div className="App">
      <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path='/' component={Home}></Route>
          <Route path='/contact' component={Contact}></Route>
          <Route path='/website-development' component={WebsiteDevelopment}></Route>
          <Route path='/paid-marketing' component={PaidMarketing}></Route>
          <Route path='/search-engine-optimization' component={SEO}></Route>
          <Route path='/crm-automation' component={CRM}></Route>
          <Route path='/branding-strategies' component={BrandingStrategies}></Route>
          <Route path='/email-marketing' component={EmailMarketing}></Route>
          <Route path='/pay-per-click' component={PPC}></Route>
          <Route path='/social-media-presence' component={SMP}></Route>
          <Route path='/portfolio' component={Portfolio}></Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
