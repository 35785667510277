import React from 'react'
import Sidebar from './sidebar'
import { Link } from 'react-router-dom'
const WebsiteDevelopment = () => {
  return (
    <>
    <section id="ori-breadcrumbs" className="ori-breadcrumbs-section website-dev position-relative" style={{backgroundColor: "#3e3e3e", backgroundImage: "url('/assets/img/headers/website-development.jpg')"}}>
		<div className="container">
			<span className="buttons prev-service wow fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms">
				<Link to="/branding-strategies"><img src={"/assets/img/hero/down-arrow.png"} /></Link>
				</span>
			<div className="ori-breadcrumb-content text-uppercase text-left ul-li wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
				<h1><span>Website</span> <br />Development</h1>
			</div>
			<span className="buttons next-service wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
				<Link to="/paid-marketing"><img src={"/assets/img/hero/down-arrow.png"} /></Link>
			</span>
		</div>
	</section>

    <section id="ori-service-details" className="ori-service-details-section position-relative" style={{backgroundImage: "url('assets/img/inner-pages/bg-home.png')", backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundColor: '#000000c2', backgroundBlendMode: 'overlay'}}>
		<div className="container">
			<div className="ori-service-details-content-wrapper">
				<div className="row">
					<div className="col-lg-8">
						<div className="ori-service-details-content">
							<div className="ori-about-play-area position-relative">
								<div className="ori-about-play-img wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
									<img src={"/assets/img/inner-pages/svg/web-dev.svg"} alt="Website Development InfodeliX" />
								</div>
							</div>
							<div className="ori-service-details-text pera-content wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1200ms">
								<p>We believe in making custom designs that are goal-oriented and in line with the brand ethos. We adhere to the latest trends and ensure our designs are optimized to empower you. Our expert team of storytellers and imaginators co-create and come up with quirky, subtle, whacky, etc. ideas and the imagination is put into action using the latest technology and design templates to create websites, videos, and content which is unique, fast, and resonates with the target audience.</p>
								<p>Since the attention span of every user is only about 5 mins as of date, the ideas have to be eye-catching and to the point. Our team of content creators and designers have a rich and vast experience in delivering for multiple brands in the given time which has led to award-winning work for some of our esteemed clients.</p>
								<div className="ori-service-details-process ul-li-block wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms">
									<h2>Service Process</h2>
									<div className="ori-service-process-item-wrap d-flex flex-wrap">
										<div className="ori-service-process-item pera-content">
											<h3><span>01</span> Creative Analysis </h3>
											<p>It is important to analyse and understand each creative’s performance in order to enhance customer engagement.</p>
										</div>
										<div className="ori-service-process-item pera-content">
											<h3><span>02</span> Design & Scatches </h3>
											<p>The designs are ideated and created by our team of experts who use the latest technology and tools to curate the best designs.</p>
										</div>
										<div className="ori-service-process-item pera-content">
											<h3><span>03</span> Prototype & Wireframing  </h3>
											<p>Before the design is implemented, a working prototype is created so that changes and improvements can be made as per customer requirements.</p>
										</div>
										<div className="ori-service-process-item pera-content">
											<h3><span>04</span> Delivery & Deploy  </h3>
											<p>Once the design is finalized the website is deployed and taken live.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-4">
						<Sidebar />
					</div>
				</div>
			</div>
		</div>
	</section>
    
    </>
  )
}

export default WebsiteDevelopment